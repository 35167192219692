import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Container, Grid, Box } from "@mui/material";

const Map = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "world" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
      }}
      marginBottom={{ xs: 6, sm: 10 }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          textAlign={"center"}
          fontWeight="bold"
          padding={2}
        >
          Our impact
        </Typography>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GatsbyImage
            image={getImage(data.file.childImageSharp)}
            key={data.file.name}
            fadeIn={false}
            loading="eager"
          />

          <Grid
            container
            className="counter"
            padding={{ xs: 1, sm: 5 }}
            justifyContent="center"
          >
            <Grid
              container
              item
              xs={4}
              sm={3}
              flexDirection={"column"}
              alignItems={{ xs: "baseline", sm: "center" }}
            >
              <Grid item textAlign={"center"}>
                <Typography variant="h2" color="#8F4FFF">
                  <CountUp end={70} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </Typography>
              </Grid>
              <Grid item textAlign={"center"}>
                <Typography variant="h6" color="#8F4FFF">
                  {" "}
                  countries
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={4}
              sm={3}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item textAlign={"center"}>
                <Typography variant="h2" color="#8F4FFF">
                  {" "}
                  <CountUp end={2000} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </Typography>
              </Grid>
              <Grid item textAlign={"center"}>
                <Typography variant="h6" color="#8F4FFF">
                  devices
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export default Map;
