import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Awards = () => {
  const data = useStaticQuery(graphql`
    query {
      awards: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativePath: { regex: "/(avards)/" }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 120
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  return (
    <Container maxWidth="lg">
      <Typography
        variant="h2"
        fontWeight={"bold"}
        textAlign="center"
        paddingBottom={4}
        paddingTop={{ xs: 4, sm: 0 }}
      >
        {" "}
        Awards
      </Typography>

      <Grid
        container
        alignItems={"center"}
        justifyContent="center"
        marginBottom={{ xs: 6, sm: 10 }}
      >
        {data.awards.edges.map(({ node }, i) => {
          const image = getImage(node);
          return (
            <Grid item xs={4} sm={2} padding={{ xs: "0.5em", sm: "1em" }}>
              <GatsbyImage
                image={image}
                key={node.name}
                fadeIn={false}
                loading="eager"
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Awards;
