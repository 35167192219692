import React from "react";
import { Box, Grid } from "@mui/material";

import { Typography, Container } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const dotsimg1 = {
  width: "70px",
  paddingRight: "2em",
};

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      dots: file(name: { regex: "/(dots)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
      }
      Federico_Acosta: file(name: { regex: "/(Federico)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Fernando_Benito: file(name: { regex: "/(Fernando)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Giuliana_Aschauer: file(name: { regex: "/(Giuliana)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Marc_Frigola: file(name: { regex: "/(Marc)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Harold_Vallejo: file(name: { regex: "/(Harold)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Rafael_Azpurua: file(name: { regex: "/(Rafael)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Edgar_Lopez: file(name: { regex: "/(Edgar)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Maria_Avila: file(name: { regex: "/(Avila)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Jose_Alonso: file(name: { regex: "/(Jose)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Krystyna_Topchyi: file(name: { regex: "/(Krystyna)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Marta_Jalon: file(name: { regex: "/(Jalon)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Santiago_Tafur: file(name: { regex: "/(Santiago)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Frida_Alejandra: file(name: { regex: "/(Frida_Alejandra)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Leon: file(name: { regex: "/(Leon)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Oliver: file(name: { regex: "/(Oliver)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
      Mafe: file(name: { regex: "/(Mafe)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 70
          )
        }
        name
      }
    }
  `);

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent={"center"}>
        <GatsbyImage image={getImage(data.dots)} imgStyle={dotsimg1} />
        <Typography
          variant="h2"
          fontWeight={"bold"}
          style={{ textAlign: "center" }}
        >
          The team
        </Typography>
      </Grid>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ marginTop: "5em" }}
        marginBottom={{ xs: 6, sm: 10 }}
      >
        <Grid className="grid" container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Federico_Acosta)}
              className="saturate"
            />
            <div item className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Federico Acosta
              </Typography>
              <Typography>Co-founder and CEO</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Fernando_Benito)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Fernando Benito
              </Typography>
              <Typography>Co-founder and CTO</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            {" "}
            <GatsbyImage
              image={getImage(data.Giuliana_Aschauer)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Giuliana Menezes
              </Typography>
              <Typography>Marketing Manager</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Marc_Frigola)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Marc Frigola
              </Typography>
              <Typography>Head of Sales</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Harold_Vallejo)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Harold Vallejo
              </Typography>
              <Typography>Hardware Project Manager</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Rafael_Azpurua)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Rafael Azpurua
              </Typography>
              <Typography>Sales Director Americas</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Edgar_Lopez)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Edgar Lopez
              </Typography>
              <Typography>Sales Engineer</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Maria_Avila)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Maria Avila
              </Typography>
              <Typography>Product Manager</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Jose_Alonso)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Jose Alonso
              </Typography>
              <Typography>Mentor</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Krystyna_Topchyi)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Krystyna Topchyi
              </Typography>
              <Typography>Web Developer</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Marta_Jalon)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Marta Jalón
              </Typography>
              <Typography>Sales Manager APAC</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Santiago_Tafur)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Santiago Tafur
              </Typography>
              <Typography>iOS Developer</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage
              image={getImage(data.Frida_Alejandra)}
              className="saturate"
            />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Frida Alejandra Gallardo Hernandez
              </Typography>
              <Typography>Office Manager</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage image={getImage(data.Leon)} className="saturate" />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Leon Johannsen
              </Typography>
              <Typography>Logistics Specialist</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage image={getImage(data.Mafe)} className="saturate" />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Maria Fernanda Ordonez
              </Typography>
              <Typography>Customer Success Specialist</Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={3} className="team">
            <GatsbyImage image={getImage(data.Oliver)} className="saturate" />
            <div className="team_text">
              <Typography variant="h6" color="white" fontWeight={"bold"}>
                Oliver
              </Typography>
              <Typography>Chief Happiness Officer</Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Team;
